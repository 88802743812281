<template>
  <div class="section">
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Blog </h1>
        </div>
      </div>
      <div class="level-right pr-5">
        <div class="level-item">
          <router-link
            :to="{ name: 'comentarios-blog' }"
            class="button button-givu has-text-centered-mobile"
          >
            Comentarios
          </router-link>
        </div>
        <div class="level-item">
            <router-link
              :to="{ name: 'agregar-blog' }"
              class="button button-givu has-text-centered-mobile"
            >
              Agregar entrada
            </router-link>
        </div>
        <div class="level-item">
            <router-link
              :to="{ name: 'blog-categorias' }"
              class="button button-givu has-text-centered-mobile"
            >
              Categorías
            </router-link>
        </div>
      </div>
    </nav>

    <div class="columns is-multiline">
      <blog-list
        :postList="postList"
        :page="page"
        :pages="pages"
        :loading="loading"
        :paginator="paginator"
        :changeStatus="changeStatus"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
const DEFAULT_DISPLAY_SIZE = 10

export default {
  name: 'Blog',
  components: {
    BlogList: () => import('@/components/Blog/BlogList')
  },
  data () {
    return {
      postList: [],
      page: 1,
      pages: 1,
      loading: false
    }
  },
  methods: {
    ...mapActions(['getPostList', 'changePostStatus']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.$router.push({ name: 'blog', query: { page } })
      this.getPostList({ page, size: DEFAULT_DISPLAY_SIZE })
        .then(resp => {
          this.postList = resp.payload
          this.pages = resp.totalPage
          this.loading = false
        })
    },
    async changeStatus (id) {
      let post = await this.changePostStatus(id)
      if (post) {
        let postList = await this.getPostList({ page: this.page, size: DEFAULT_DISPLAY_SIZE })
        this.postList = postList.payload
        this.pages = postList.totalPage
      }
      this.loading = false
    }
  },
  beforeMount () {
    this.loading = true
    const { page } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.getPostList({ page: page || this.page, size: DEFAULT_DISPLAY_SIZE })
      .then(resp => {
        this.postList = resp.payload
        this.pages = resp.totalPage
        this.loading = false
      })
  }
}
</script>

<style lang="scss" scoped>
  .section {
    padding: 0 20px 0 20px;
  }
</style>
